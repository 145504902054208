import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import PublishIcon from "@mui/icons-material/Publish";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from "@mui/material";

import { StrokeInput } from "../input_utils";
import { Table } from "../table";

export function BonusScorecard(props) {
  const { scorecard, game, course, callback } = props;
  const [state, setState] = React.useState(
    JSON.parse(JSON.stringify(scorecard))
  );
  const [submitting, setSubmitting] = React.useState(false);

  const ChangePending = () => {
    if (state.chip_ins !== scorecard.chip_ins) return true;
    if (state.hole_in_ones !== scorecard.hole_in_ones) return true;
    return false;
  };

  const stickyStyle = {
    backgroundColor: "white",
    position: "sticky",
    left: "0",
    zIndex: "999",
  };

  const columns = [
    { id: "category", label: "", align: "left", sx: stickyStyle },
    { id: "value", label: "Points", align: "center" },
  ];

  const rows = [
    {
      category: "Chip-ins",
      value: (
        <StrokeInput
          value={state.chip_ins ? state.chip_ins : ""}
          onChange={(event) => {
            setState((prevState) => ({
              ...prevState,
              chip_ins: parseInt(event.target.value),
            }));
          }}
        />
      ),
    },
    {
      category: "Hole-in-ones",
      value: (
        <StrokeInput
          value={state.hole_in_ones ? state.hole_in_ones : ""}
          onChange={(event) => {
            setState((prevState) => ({
              ...prevState,
              hole_in_ones: parseInt(event.target.value),
            }));
          }}
        />
      ),
    },
  ];

  return (
    <Stack sx={{ display: "flex" }} justifyContent="space-between" spacing={2}>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <Table columns={columns} rows={rows} />
      </TableContainer>
      <Stack
        direction="row"
        sx={{ display: "flex" }}
        justifyContent="space-between"
        spacing={1}
      >
        <Button
          variant="contained"
          sx={{ flex: "1" }}
          startIcon={submitting ? null : <PublishIcon />}
          disabled={!ChangePending() || submitting}
          onClick={() => {
            setSubmitting(true);
            callback(state).finally(() => setSubmitting(false));
          }}
        >
          {submitting ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            "submit"
          )}
        </Button>
        <Button
          variant="contained"
          disabled={!ChangePending() || submitting}
          onClick={() => setState(JSON.parse(JSON.stringify(scorecard)))}
        >
          <RestorePageIcon />
        </Button>
      </Stack>
      <BonusRules game={game} />
    </Stack>
  );
}

export function BonusRules({ game }) {
  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingTop: "25px" }}>
        Bonus Points
      </Typography>
      <Typography>
        Add any bonus points you got throughout the tournament.
      </Typography>
    </>
  );
}
