export async function getCourses() {
  return getJson("courses");
}

export async function getGames() {
  return getJson("games");
}

export async function getScorecards() {
  return getJson("scorecards");
}

export async function getScores() {
  return getJson("scores");
}

export async function getUsers() {
  return getJson("users");
}

export async function submitScorecard(user_id, game, scorecard) {
  return apiRequest(
    "scorecards",
    "PUT",
    {
      "Content-Type": "application/json",
    },
    JSON.stringify({
      user_id: user_id,
      game: game,
      scorecard: scorecard,
    })
  );
}

export async function updateUser(user, handicap) {
  return apiRequest(
    "user",
    "PUT",
    {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    JSON.stringify({ handicap: handicap })
  )
    .then((response) => response.text())
    .then((data) => JSON.parse(data));
}

function getApiUrl() {
  return process.env.NODE_ENV === "production"
    ? "https://api-h422ljwxva-uw.a.run.app"
    : "http://127.0.0.1:8080";
}

async function getJson(api) {
  return apiRequest(api, "GET")
    .then((response) => response.text())
    .then((data) => JSON.parse(data));
}

async function apiRequest(api, method, headers, body) {
  const apiUrl = `${getApiUrl()}/${api}`;
  return fetch(`${apiUrl}`, {
    method: method,
    headers: headers,
    body: body,
  })
    .then((response) => {
      if (response.ok) {
        return response;
      } else {
        console.log(response);
        throw new Error("Bad response from server");
      }
    })
    .catch((err) => {
      console.log(
        `api: ${apiUrl}\nmethod: ${method}\nheaders: ${headers}\nbody: ${body}`
      );
      console.log(err);
      throw new Error("Server problem");
    });
}
