import React from "react";
import { getAuth } from "firebase/auth";
import GoogleIcon from "@mui/icons-material/Google";
import LogoutIcon from "@mui/icons-material/Logout";
import PublishIcon from "@mui/icons-material/Publish";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/system/Stack";

import { updateUser } from "./api_utils";
import { login } from "./auth_utils";

export default function Profile() {
  const [state, setState] = React.useState({
    user: null,
    waiting: false,
  });

  if (!getAuth().currentUser && state.user) {
    setState((prevState) => ({ ...prevState, user: null }));
  }

  if (!state.waiting && getAuth().currentUser && !state.user) {
    setState((prevState) => ({ ...prevState, waiting: true }));
    updateUser(getAuth().currentUser)
      .then((user) =>
        setState((prevState) => ({
          ...prevState,
          user: user,
          handicap: user.handicap,
        }))
      )
      .catch((err) =>
        getAuth()
          .signOut()
          .then(() =>
            setState((prevState) => ({
              ...prevState,
              login_error: "Failed to retrieve user",
            }))
          )
      )
      .finally(() =>
        setState((prevState) => ({ ...prevState, waiting: false }))
      );
  }

  if (state.waiting) {
    return <Loading />;
  } else if (state.user) {
    return (
      <>
        <Stack
          sx={{ display: "flex" }}
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
        >
          <Box
            sx={{
              borderRadius: "50%",
            }}
            component="img"
            src={state.user.profile_pic.replace("s96-c", "s256-c")}
            loading="lazy"
            width="35%"
          />

          <TextField
            variant="outlined"
            label="Handicap"
            type="number"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              style: { textAlign: "center" },
            }}
            sx={{ width: "100px" }}
            value={state.handicap}
            onChange={(event) =>
              setState((prevState) => ({
                ...prevState,
                handicap: event.target.value,
              }))
            }
            onBlur={() => {
              if (state.handicap === "") {
                setState((prevState) => ({ ...prevState, handicap: 0 }));
              }
            }}
          />
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Button
              variant="contained"
              size="large"
              disabled={
                (parseFloat(state.handicap) || 0) === state.user.handicap
              }
              startIcon={<PublishIcon />}
              onClick={() => {
                setState((prevState) => ({ ...prevState, waiting: true }));
                updateUser(getAuth().currentUser, state.handicap)
                  .then((user) =>
                    setState((prevState) => ({
                      ...prevState,
                      user: user,
                      handicap: user.handicap,
                    }))
                  )
                  .catch((err) =>
                    getAuth()
                      .signOut()
                      .then(() =>
                        setState((prevState) => ({
                          ...prevState,
                          login_error: "Failed to update handicap",
                        }))
                      )
                  )
                  .finally(() =>
                    setState((prevState) => ({ ...prevState, waiting: false }))
                  );
              }}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              disabled={
                (parseFloat(state.handicap) || 0) === state.user.handicap
              }
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  handicap: state.user.handicap,
                }))
              }
            >
              <RestorePageIcon />
            </Button>
          </Stack>
          <Box sx={{ height: "50px" }} />
          <Stack
            sx={{ display: "flex", width: "100%" }}
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={1}
          >
            <Typography variant="body1">
              <strong>Name:</strong> {state.user.name}
            </Typography>
            <Typography variant="body1">
              <strong>email:</strong> {state.user.email}
            </Typography>
            <Box sx={{ height: "50px" }} />
            <SignOutButton />
          </Stack>
        </Stack>
      </>
    );
  } else {
    return (
      <Stack sx={{ display: "flex" }} alignItems="center">
        <SignInWithGoogleButton
          onClick={() => {
            setState((prevState) => ({ ...prevState, waiting: true }));
            login()
              .catch((err) =>
                setState((prevState) => ({
                  ...prevState,
                  login_error: "Login failed",
                }))
              )
              .finally(() =>
                setState((prevState) => ({ ...prevState, waiting: false }))
              );
          }}
        />
        <Snackbar
          open={!!state.login_error}
          onClose={() =>
            setState((prevState) => ({ ...prevState, login_error: null }))
          }
          sx={{ marginBottom: "56px" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {state.login_error}
          </Alert>
        </Snackbar>
      </Stack>
    );
  }
}

function Loading() {
  return (
    <Stack
      sx={{ height: "75vh" }}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Stack>
  );
}

function SignInWithGoogleButton(props) {
  return (
    <Button variant="contained" {...props}>
      <GoogleIcon sx={{ padding: "3px" }} />
      <Typography sx={{ padding: "3px" }}>{"Sign in with Google"}</Typography>
    </Button>
  );
}

function SignOutButton(props) {
  return (
    <Button
      variant="contained"
      startIcon={<LogoutIcon />}
      onClick={() => {
        getAuth().signOut();
      }}
      {...props}
    >
      <Typography sx={{ padding: "0px" }}>{"Sign Out"}</Typography>
    </Button>
  );
}
