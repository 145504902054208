import ReactDOM from "react-dom/client";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import App from "./app";
import { initFirebase } from "./auth_utils.js";

initFirebase();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
