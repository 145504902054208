import React from "react";
import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  getAuth,
  GoogleAuthProvider,
  setPersistence,
  signInWithPopup,
} from "firebase/auth";
import GoogleIcon from "@mui/icons-material/Google";
import { Alert, Button, Snackbar, Stack } from "@mui/material";

import { updateUser } from "./api_utils";
import { Loading } from "./loading";

const firebaseConfig = {
  apiKey: "AIzaSyD6jIty8TZ9LX8-O0cL0AsdG51A8f_zW0A",
  authDomain: "ball-shaggers-invitational.firebaseapp.com",
  projectId: "ball-shaggers-invitational",
  storageBucket: "ball-shaggers-invitational.appspot.com",
  messagingSenderId: "683826154025",
  appId: "1:683826154025:web:148199f172b7c2aece2a38",
  measurementId: "G-P080V2E69P",
};

export function initFirebase() {
  initializeApp(firebaseConfig);
  setPersistence(getAuth(), browserLocalPersistence);
}

export async function login() {
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/userinfo.email");
  provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
  provider.setCustomParameters({
    prompt: "select_account",
  });

  return await signInWithPopup(getAuth(), provider);
}

export function SignInPage(props) {
  const { onSuccess } = props;
  const [state, setState] = React.useState({
    currentUser: getAuth().currentUser,
  });

  if (state.currentUser && !state.user) {
    updateUser(state.currentUser)
      .then((user) => {
        setState((prevState) => ({ ...prevState, user: user }));
        return onSuccess(user);
      })
      .catch((err) => {
        getAuth()
          .signOut()
          .then(() =>
            setState((prevState) => ({
              ...prevState,
              currentUser: getAuth().currentUser,
              user: null,
              error: "Sign in failed",
            }))
          );
      });
    return <Loading />;
  }

  return (
    <Stack sx={{ display: "flex" }} alignItems="center">
      <Button
        variant="contained"
        onClick={() =>
          login()
            .then(() =>
              setState((prevState) => ({
                ...prevState,
                currentUser: getAuth().currentUser,
              }))
            )
            .catch((err) => {
              setState((prevState) => ({
                ...prevState,
                error: "Sign in failed",
              }));
            })
        }
        startIcon=<GoogleIcon />
      >
        "Sign in with Google"
      </Button>
      <Snackbar
        sx={{ marginBottom: "56px" }}
        open={!!state.error}
        onClose={() => setState((prevState) => ({ ...prevState, error: null }))}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {state.error}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
