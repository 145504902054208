import React from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

export function Table(props) {
  const { columns, rows, size } = props;
  const expandable = rows.some((row) => row.subRow);

  const [state, setState] = React.useState({
    rowIndices: [...Array(rows.length).keys()],
    sortColumn: null,
    sortDirection: null,
    initialized: false,
  });

  const handleSortRequest = (column) => {
    let sortColumn = state.sortColumn;
    let sortDirection = state.sortDirection;
    let curRows = JSON.parse(JSON.stringify(state.rowIndices));

    if (sortColumn === column.id) {
      sortDirection =
        column.sortable[
          (column.sortable.findIndex(
            (element) => element === state.sortDirection
          ) +
            1) %
            column.sortable.length
        ];
    } else {
      sortColumn = column.id;
      sortDirection = column.sortable[0];
    }

    if (sortDirection) {
      curRows.sort((a, b) => {
        const a_val = rows[a][sortColumn];
        const b_val = rows[b][sortColumn];
        const sign = sortDirection === "asc" ? 1 : -1;
        return a_val < b_val ? sign * -1 : a_val > b_val ? sign * 1 : 0;
      });
    } else {
      curRows = [...Array(rows.length).keys()];
    }
    setState((prevState) => ({
      ...prevState,
      rowIndices: curRows,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    }));
  };

  if (!state.initialized) {
    for (const column of columns) {
      if (column.sorted) {
        handleSortRequest(column);
      }
    }
    setState((prevState) => ({ ...prevState, initialized: true }));
  }

  const HeaderRow = () => {
    return (
      <TableRow>
        {expandable ? <TableCell sx={{ width: "1px" }} /> : <></>}
        {columns.map((x) => (
          <TableCell key={x.id} align={x.align} sx={x.sx}>
            {x.sortable ? (
              <TableSortLabel
                active={state.sortColumn === x.id && !!state.sortDirection}
                direction={state.sortDirection ? state.sortDirection : "asc"}
                onClick={(ev) => {
                  handleSortRequest(x);
                }}
              >
                {x.label}
              </TableSortLabel>
            ) : (
              x.label
            )}
          </TableCell>
        ))}
        <TableCell sx={{ flex: "1" }} />
      </TableRow>
    );
  };

  return (
    <MuiTable size={size ? size : null}>
      <TableHead>
        <HeaderRow />
      </TableHead>
      <TableBody>
        {state.rowIndices.map((rowIndex, index) =>
          rows[rowIndex].subRow ? (
            <ExpandableRow key={index} row={rows[rowIndex]} columns={columns} />
          ) : (
            <NormalRow key={index} row={rows[rowIndex]} columns={columns} />
          )
        )}
      </TableBody>
    </MuiTable>
  );
}

function ExpandableRow(props) {
  const { row, columns } = props;
  const [state, setState] = React.useState({ expanded: false });

  if (state.row !== row.name) {
    setState((prevState) => ({ ...prevState, row: row.name, expanded: false }));
  }

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ width: "1px" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                expanded: !prevState.expanded,
              }))
            }
          >
            {state.expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        {Object.values(columns).map((column, index) => (
          <TableCell key={index} align={column.align} sx={column.sx}>
            {row[column.id]}
          </TableCell>
        ))}
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length + 2}
        >
          <Collapse in={state.expanded} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                columns={row.subRow.columns}
                rows={row.subRow.rows}
                size="small"
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function NormalRow(props) {
  const { row, columns } = props;

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {Object.values(columns).map((column, index) => (
          <TableCell key={index} align={column.align} sx={column.sx}>
            {row[column.id]}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
}
