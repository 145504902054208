import { isMobile } from "react-device-detect";

import MobileApp from "./mobile_app";

export default function App() {
  if (isMobile) {
    return <MobileApp />;
  }
  return <MobileApp />;
}
