import React from "react";
import {
  Alert,
  AlertTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import {
  getCourses,
  getGames,
  getScorecards,
  getScores,
  getUsers,
  submitScorecard,
} from "./api_utils";
import { SignInPage } from "./auth_utils";
import { BingoBangoBongoScorecard } from "./games/bingo_bango_bongo";
import { BonusScorecard } from "./games/bonus";
import { ScrambleScorecard } from "./games/scramble";
import { StablefordScorecard } from "./games/stableford";
import { WolfScorecard } from "./games/wolf";
import { Loading } from "./loading";

export default function Scores() {
  const [state, setState] = React.useState({
    pendingAuthentication: false,
  });

  const DataLoaded = () => {
    return (
      !!state.courses &&
      !!state.games &&
      !!state.scorecards &&
      !!state.scores &&
      !!state.users
    );
  };

  if (state.error) {
    return (
      <Alert severity="error">
        <AlertTitle>{state.error.title}</AlertTitle>
        {state.error.msg}
      </Alert>
    );
  }

  if (!state.user) {
    return (
      <SignInPage
        onSuccess={(user) =>
          setState((prevState) => ({ ...prevState, user: user }))
        }
      />
    );
  }

  if (!DataLoaded()) {
    Promise.all([
      getCourses(),
      getGames(),
      getScorecards(),
      getScores(),
      getUsers(),
    ])
      .then((results) => {
        const [courses, games, scorecards, scores, users] = results;

        const gameList = Object.entries(games).map(([game_type, game]) => ({
          value: game_type,
          label: game.name,
        }));

        const initialGame = gameList[0].value || null;
        const initialScorecard = scorecards[state.user.user_id][initialGame];

        setState((prevState) => ({
          ...prevState,
          game: initialGame,
          gameList: gameList,
          scorecard: initialScorecard,
          courses: courses,
          games: games,
          scorecards: scorecards,
          scores: scores,
          users: users,
        }));
      })
      .catch((err) =>
        setState((prevState) => ({
          ...prevState,
          error: { title: "Failed to load data", msg: err.message },
        }))
      );
    return <Loading />;
  }

  const submitCallback = async (scorecard) =>
    submitScorecard(state.user.user_id, state.game, scorecard)
      .catch((err) =>
        setState((prevState) => ({
          ...prevState,
          error: { title: "Error", msg: "Failed to submit" },
        }))
      )
      .then(() => getScorecards())
      .then((scorecards) => {
        setState((prevState) => ({
          ...prevState,
          scorecards: scorecards,
          scorecard: scorecards[prevState.user.user_id][prevState.game],
        }));
      })
      .catch((err) =>
        setState((prevState) => ({
          ...prevState,
          error: { title: "Error", msg: "Failed to get updated scorecard" },
        }))
      );

  return (
    <Stack sx={{ display: "flex" }} justifyContent="space-between" spacing={2}>
      <GameSelect
        game={state.game}
        gameList={state.gameList}
        onChange={(event) =>
          setState((prevState) => ({
            ...prevState,
            game: event.target.value,
            scorecard:
              prevState.scorecards[prevState.user.user_id][event.target.value],
          }))
        }
      />
      <Scorecard
        userId={state.user.user_id}
        gameId={state.game}
        scorecard={state.scorecard}
        courses={state.courses}
        games={state.games}
        users={state.users}
        submitCallback={submitCallback}
      />
    </Stack>
  );
}

function GameSelect(props) {
  const { game, gameList, onChange } = props;

  return (
    <FormControl fullWidth>
      <InputLabel id="foo">Game</InputLabel>
      <Select labelId="bar" value={game} label="Game" onChange={onChange}>
        {gameList.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function Scorecard(props) {
  const { userId, gameId, scorecard, courses, games, users, submitCallback } =
    props;

  const game = games[gameId];
  const course = courses[game.course];

  const game_type = game.game_type;
  if (game_type === "wolf") {
    return (
      <WolfScorecard
        userId={userId}
        scorecard={scorecard}
        game={game}
        course={course}
        users={users}
        callback={submitCallback}
      />
    );
  } else if (game_type === "bingo_bango_bongo") {
    return (
      <BingoBangoBongoScorecard
        scorecard={scorecard}
        game={game}
        course={course}
        callback={submitCallback}
      />
    );
  } else if (game_type === "stableford") {
    return (
      <StablefordScorecard
        scorecard={scorecard}
        game={game}
        course={course}
        callback={submitCallback}
      />
    );
  } else if (game_type === "scramble") {
    return (
      <ScrambleScorecard
        scorecard={scorecard}
        game={game}
        course={course}
        callback={submitCallback}
      />
    );
  } else if (game_type === "bonus") {
    return (
      <BonusScorecard
        scorecard={scorecard}
        game={game}
        course={course}
        callback={submitCallback}
      />
    );
  }

  return <Alert severity="warning">Unrecognized game</Alert>;
}
