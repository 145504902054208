import React from "react";
import PublishIcon from "@mui/icons-material/Publish";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from "@mui/material";

import { StrokeInput } from "../input_utils";
import { Table } from "../table";

export function ScrambleScorecard(props) {
  const { scorecard, game, course, callback } = props;
  const [state, setState] = React.useState(
    JSON.parse(JSON.stringify(scorecard))
  );
  const [submitting, setSubmitting] = React.useState(false);

  const ChangePending = () => {
    for (let i = 0; i < 18; ++i) {
      if (state.strokes[i] !== scorecard.strokes[i]) return true;
    }
    return false;
  };

  const stickyStyle = {
    backgroundColor: "white",
    position: "sticky",
    left: "0",
    zIndex: "999",
  };

  const columns = [
    { id: "category", label: "Hole", align: "left", sx: stickyStyle },
  ].concat(
    [...Array(18).keys()].map((x) => ({
      id: x,
      label: `${x + 1}`,
      align: "center",
    }))
  );

  const rows = [
    {
      category: "Par",
      ...course.hole_par,
    },
    {
      category: "Index",
      ...course.hole_index,
    },
    {
      category: "Strokes",
      ...Object.fromEntries(
        [...Array(18).keys()].map((hole) => [
          hole,
          <StrokeInput
            value={state.strokes[hole] ? state.strokes[hole] : ""}
            onChange={(event) => {
              setState((prevState) => {
                let strokes = prevState.strokes;
                strokes[hole] = parseInt(event.target.value) || 0;
                return { ...prevState, strokes: [...strokes] };
              });
            }}
          />,
        ])
      ),
    },
  ];

  return (
    <Stack sx={{ display: "flex" }} justifyContent="space-between" spacing={2}>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <Table columns={columns} rows={rows} />
      </TableContainer>
      <Stack
        direction="row"
        sx={{ display: "flex" }}
        justifyContent="space-between"
        spacing={1}
      >
        <Button
          variant="contained"
          sx={{ flex: "1" }}
          startIcon={submitting ? null : <PublishIcon />}
          disabled={!ChangePending() || submitting}
          onClick={() => {
            setSubmitting(true);
            callback(state).finally(() => setSubmitting(false));
          }}
        >
          {submitting ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            "submit"
          )}
        </Button>
        <Button
          variant="contained"
          disabled={!ChangePending() || submitting}
          onClick={() => setState(JSON.parse(JSON.stringify(scorecard)))}
        >
          <RestorePageIcon sx={{ padding: "3px", width: "25px" }} />
        </Button>
      </Stack>
      <ScrambleRules game={game} />
    </Stack>
  );
}

export function ScrambleRules({ game }) {
  const handicap_columns = [
    { id: "group_size", label: "Group Size", align: "center" },
    { id: "handicap", label: "Handicap", align: "center" },
  ];
  const handicap_rows = [
    { group_size: "Twosome", handicap: "0.35 * A + 0.15 * B" },
    { group_size: "Threesome", handicap: "0.2 * A + 0.15 * B + 0.1 * C" },
    {
      group_size: "Foursome",
      handicap: "0.2 * A + 0.15 * B + 0.1 * C",
    },
  ];
  const point_columns = [
    { id: "net_to_par", label: "Net to Par", align: "center" },
    { id: "points", label: "Points", align: "center" },
  ];

  const point_rows = Object.entries(game.config.point_multipliers)
    .sort((a, b) => {
      const a_int = parseInt(a);
      const b_int = parseInt(b);
      if (a_int < b_int) return 1;
      if (a_int > b_int) return -1;
      return 0;
    })
    .map(([net, points]) => ({ net_to_par: net, points: points }));

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingTop: "25px" }}>
        Scramble Rules
      </Typography>
      <Typography>Normal scramble but with stableford rules.</Typography>
      <Typography>
        In a scramble, your group plays as a team. Everyone tees off, and then
        your team chooses the best shot. Everyone hits their next shot from that
        chosen position. Keep choosing the best shot and hitting from there
        until you finish the hole. Everyone on your team records the same score
        for each hole.
      </Typography>
      <Typography>
        Instead of just adding up all of your strokes, you get points based on
        your team{"'"}s net score to par (after strokes given) on each hole.
      </Typography>
      <Typography>
        Each team gets a single handicap computed from its members{"'"}{" "}
        handicaps as follows (where A is the lowest handicap and D is the
        highest):
      </Typography>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <Table columns={handicap_columns} rows={handicap_rows} size="small" />
      </TableContainer>
      <Typography>Per-hole point values are:</Typography>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <Table columns={point_columns} rows={point_rows} size="small" />
      </TableContainer>
    </>
  );
}
