import { Box, Link } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function Home() {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Welcome
      </Typography>
      <Typography>
        Welcome to the 2nd Ball Shaggers Invitational – Where Golf Mastery Takes
        Center Stage Amidst the Scenic Beauty of Naples, Florida!
      </Typography>
      <Box sx={{ display: "flex" }} justifyContent="center" alignItems="center">
        <Box
          sx={{
            borderRadius: "10px",
            margin: "25px",
          }}
          component="img"
          src="chubbs-heaven.jpg"
          loading="lazy"
          width="80%"
        />
      </Box>
      <Typography sx={{ marginBottom: "12pt" }}>
        Esteemed participants, welcome to this distinguished 13-man golfing
        affair, set against the breathtaking backdrop of Naples. The Ball
        Shaggers Invitational beckons, promising a showcase of skill, precision,
        and the pursuit of excellence on the fairways.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Event Details</strong>
      </Typography>
      <Typography sx={{ marginBottom: "12pt", marginLeft: "12pt" }}>
        <strong>Dates:</strong> January 17-21, 2024
        <br />
        <strong>Location:</strong> Naples, FL
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>The Pursuit of the Ball Shaggers Cup</strong>
      </Typography>
      <Typography>
        Anticipate four rounds of meticulous golf, where each stroke is a
        strategic move toward claiming the prestigious Ball Shaggers Cup.
        Navigate the verdant course, overcoming challenges with calculated
        precision, as the tournament unfurls in a display of golfing prowess.
      </Typography>
      <Box sx={{ display: "flex" }} justifyContent="center" alignItems="center">
        <Box
          sx={{
            borderRadius: "10px",
            margin: "25px",
          }}
          component="img"
          src="trophy.jpg"
          loading="lazy"
          width="60%"
        />
      </Box>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Luxury Meets Leisure – A Haven of Elegance</strong>
      </Typography>
      <Typography sx={{ marginBottom: "12pt" }}>
        Naples, Florida, known for its sun-soaked beaches and upscale lifestyle,
        provides the perfect backdrop for our invitational. Beyond the greens,
        indulge in the luxurious amenities and unwind in the lap of golfing
        opulence. From exquisite dining experiences to world-class
        accommodations, this event is not just about golf – it's an
        unforgettable retreat.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Tournament Highlights</strong>
      </Typography>
      <Typography sx={{ textIndent: "-8pt", marginLeft: "20pt" }}>
        <span>&#8226;</span>
        {"  "}
        <strong>Four rounds of intense competition</strong> – where each swing
        is a note in the symphony of golfing excellence
      </Typography>
      <Typography sx={{ textIndent: "-8pt", marginLeft: "20pt" }}>
        <span>&#8226;</span>
        {"  "}
        <strong>Networking opportunities with fellow golf enthusiasts</strong> –
        engage in discussions that transcend the sport, forging connections with
        like-minded participants
      </Typography>
      <Typography sx={{ textIndent: "-8pt", marginLeft: "20pt" }}>
        <span>&#8226;</span>
        {"  "}
        <strong>Spectacular views and pristine course conditions</strong> – a
        visual spectacle complementing the precision required on each hole
      </Typography>
      <Typography
        sx={{ textIndent: "-8pt", marginLeft: "20pt", marginBottom: "12pt" }}
      >
        <span>&#8226;</span>
        {"  "}
        <strong>Gala dinner and awards ceremony</strong> – an evening of
        celebration, acknowledging the mastery displayed throughout the
        tournament
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Join the Conversation</strong>
      </Typography>
      <Typography sx={{ marginBottom: "12pt" }}>
        Stay connected and share your Ball Shaggers Invitational experience with
        the world. Use our official hashtag #BallShaggersInvitational and let
        the golfing community celebrate your victories, unforgettable moments,
        and the camaraderie that makes this tournament exceptional.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>How to Stay Informed</strong>
      </Typography>
      <Typography sx={{ marginBottom: "12pt" }}>
        For live updates, scores, and exclusive behind-the-scenes content, visit
        our <Link href="https://ballshaggers.com">official website</Link>{" "}
        <s>or follow us on social media</s>.
      </Typography>
      <Typography sx={{ marginBottom: "12pt" }}>
        Welcome to the 2nd Ball Shaggers Invitational – where golfing mastery
        reigns supreme. May your swings be as precise as clockwork, your putts
        as strategic as a chess move, and the spirit of competition drive you to
        new heights on the course. Embrace the essence of golfing excellence in
        this distinguished event!
      </Typography>

      <Typography variant="h6" gutterBottom sx={{ paddingTop: "25px" }}>
        Important Links
      </Typography>
      <Typography>
        <Link href="https://maps.app.goo.gl/Qq5Q4DsRxX9XZsLk7">
          Google Maps List
        </Link>
      </Typography>
      <Typography>
        <Link href="https://maps.app.goo.gl/cKLex3xznDZtRzm99">Airbnb</Link>
      </Typography>
    </>
  );
}
