import React from "react";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableContainer,
} from "@mui/material";
import Stack from "@mui/system/Stack";

import { getCourses, getGames, getScores, getUsers } from "./api_utils";
import { BonusRules } from "./games/bonus";
import { BingoBangoBongoRules } from "./games/bingo_bango_bongo";
import { ScrambleRules } from "./games/scramble";
import { StablefordRules } from "./games/stableford";
import { WolfRules } from "./games/wolf";
import { Loading } from "./loading";
import { Table } from "./table";

export default function Leaderboard() {
  const [state, setState] = React.useState({});

  const DataLoaded = () => {
    return !!state.courses && !!state.games && !!state.scores && !!state.users;
  };

  if (state.error) {
    return (
      <Alert severity="error">
        <AlertTitle>{state.error.title}</AlertTitle>
        {state.error.msg}
      </Alert>
    );
  }

  if (!DataLoaded()) {
    Promise.all([getCourses(), getGames(), getScores(), getUsers()])
      .then((results) => {
        const [courses, games, scores, users] = results;
        setState((prevState) => ({
          ...prevState,
          courses: courses,
          games: games,
          scores: scores,
          users: users,
        }));
      })
      .catch((err) =>
        setState((prevState) => ({
          ...prevState,
          error: { title: "Failed to load data", msg: err.message },
        }))
      );
  }

  if (DataLoaded() && !state.page) {
    const pageList = [{ value: "tournament", label: "Tournament" }].concat(
      Object.entries(state.games)
        .sort()
        .map((kv) => ({
          value: kv[0],
          label: kv[1].name,
        }))
    );

    setState((prevState) => ({
      ...prevState,
      page: pageList[0].value,
      pageList: pageList,
    }));
  }

  if (!DataLoaded() || !state.page) {
    return <Loading />;
  }

  return (
    <Stack sx={{ display: "flex" }} justifyContent="space-between" spacing={2}>
      <PageSelect
        page={state.page}
        pageList={state.pageList}
        callbackFn={(event) => {
          setState((prevState) => ({ ...prevState, page: event.target.value }));
        }}
      />
      <PageTable
        page={state.page}
        courses={state.courses}
        games={state.games}
        scores={state.scores}
        users={state.users}
      />
      <PageRules page={state.page} games={state.games} />
    </Stack>
  );
}

function PageTable(props) {
  const { page, courses, games, scores, users } = props;

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
      {page === "tournament" ? (
        <TournamentTable games={games} scores={scores} users={users} />
      ) : (
        <GameTable
          game={page}
          courses={courses}
          games={games}
          scores={scores}
          users={users}
        />
      )}
    </TableContainer>
  );
}

function TournamentTable(props) {
  const { games, scores, users } = props;

  const columns = [
    { id: "name", label: "Name", align: "left" },
    {
      id: "total",
      label: "Total",
      align: "right",
      sortable: ["desc", "asc"],
      sorted: "desc",
    },
  ].concat(
    Object.keys(games)
      .sort()
      .map((x) => ({
        id: x,
        label: x,
        align: "right",
        sortable: ["desc", "asc"],
      }))
  );

  const rows = Object.entries(scores).map(([user, userScores]) =>
    Object.fromEntries(
      [["name", `${users[user].name} (${users[user].handicap})`]]
        .concat([
          [
            "total",
            Object.values(userScores).reduce(
              (tot, score) => tot + score.total,
              0
            ),
          ],
        ])
        .concat(
          Object.entries(userScores).map(([game, score]) => [game, score.total])
        )
    )
  );

  return <Table columns={columns} rows={rows} />;
}

function GameTable(props) {
  const { game, courses, games, scores, users } = props;

  const columns = [
    {
      id: "name",
      label: "Name",
      align: "left",
      sx: { width: `calc(${window.innerWidth}px - 235px)` },
    },
    {
      id: "total",
      label: "Total",
      align: "right",
      sx: { width: "1px" },
      sortable: ["desc", "asc"],
      sorted: "desc",
    },
  ];

  const course = courses[games[game].course];

  const rows = Object.entries(scores).map(([user, userScores]) => ({
    name: `${users[user].name} (${users[user].handicap})`,
    total: userScores[game].total,
    subRow: {
      columns: [
        { id: "category", label: "", align: "left" },
        { id: "total", label: "Total", align: "right" },
      ].concat(
        [...Array(18).keys()].map((x) => ({
          id: x,
          label: `${x + 1}`,
          align: "right",
        }))
      ),
      rows: [
        {
          category: "Par",
          ...course.hole_par,
        },
        {
          category: "Index",
          ...course.hole_index,
        },
      ].concat(
        userScores[game].categories.map((x) => {
          return {
            category: x.key,
            total: Object.values(x.value).reduce((a, b) => parseInt(a) + b, 0),
            ...x.value,
          };
        })
      ),
    },
  }));

  return <Table columns={columns} rows={rows} expandable={true} />;
}

function PageRules(props) {
  const { page, games } = props;
  if (page === "tournament") {
    return <></>;
  }

  const game = games[page];
  if (game.game_type === "bingo_bango_bongo") {
    return <BingoBangoBongoRules game={game} />;
  } else if (game.game_type === "scramble") {
    return <ScrambleRules game={game} />;
  } else if (game.game_type === "stableford") {
    return <StablefordRules game={game} />;
  } else if (game.game_type === "wolf") {
    return <WolfRules game={game} />;
  } else if (game.game_type === "bonus") {
    return <BonusRules game={game} />;
  }
}

function PageSelect(props) {
  const { page, pageList, callbackFn } = props;

  return (
    <FormControl fullWidth>
      <InputLabel id="pageSelect">Game</InputLabel>
      <Select
        labelId="pageSelect"
        value={page}
        label="Game"
        onChange={callbackFn}
      >
        {pageList.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
