import React from "react";
import { Helmet } from "react-helmet";
import AppBar from "@mui/material/AppBar";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import EditNoteIcon from "@mui/icons-material/EditNote";
import HomeIcon from "@mui/icons-material/Home";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PersonIcon from "@mui/icons-material/Person";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Home from "./home";
import Leaderboard from "./leaderboard";
import Profile from "./profile";
import Scores from "./scores";

export default function MobileApp() {
  const [page, setPage] = React.useState("leaderboard");
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Helmet>
      <AppBar elevation={0} sx={{ height: "100px" }}>
        <Toolbar sx={{ height: "100px" }}>
          <Typography
            variant="h6"
            justifyContent="center"
            align="center"
            sx={{ flex: "1" }}
          >
            Ball Shaggers Invitational
          </Typography>
        </Toolbar>
      </AppBar>
      <Box paddingTop="50px" paddingBottom="56px">
        <Toolbar /> {page === "home" && <Home />}
        {page === "leaderboard" && <Leaderboard />}
        {page === "scores" && <Scores />} {page === "profile" && <Profile />}
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <BottomNavigation
          value={page}
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
        >
          <BottomNavigationAction value="home" icon={<HomeIcon />} />
          <BottomNavigationAction
            value="leaderboard"
            icon={<LeaderboardIcon />}
          />
          <BottomNavigationAction value="scores" icon={<EditNoteIcon />} />
          <BottomNavigationAction value="profile" icon={<PersonIcon />} />
        </BottomNavigation>
      </Box>
    </>
  );
}
