import React from "react";
import PublishIcon from "@mui/icons-material/Publish";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  TableContainer,
} from "@mui/material";

import { ScorecardCheckbox } from "../input_utils";
import { Table } from "../table";

export function BingoBangoBongoScorecard(props) {
  const { scorecard, game, course, callback } = props;
  const [state, setState] = React.useState(
    JSON.parse(JSON.stringify(scorecard))
  );
  const [submitting, setSubmitting] = React.useState(false);

  const ChangePending = () => {
    for (let i = 0; i < 18; ++i) {
      if (state.bingos[i] !== scorecard.bingos[i]) return true;
      if (state.bangos[i] !== scorecard.bangos[i]) return true;
      if (state.bongos[i] !== scorecard.bongos[i]) return true;
    }
    return false;
  };

  const stickyStyle = {
    backgroundColor: "white",
    position: "sticky",
    left: "0",
    zIndex: "999",
  };

  const columns = [
    { id: "category", label: "Hole", align: "left", sx: stickyStyle },
  ].concat(
    [...Array(18).keys()].map((x) => ({
      id: x,
      label: `${x + 1}`,
      align: "center",
    }))
  );

  const rows = [
    {
      category: "Par",
      ...course.hole_par,
    },
    {
      category: "Index",
      ...course.hole_index,
    },
    {
      category: "Bingo",
      ...Object.fromEntries(
        [...Array(18).keys()].map((hole) => [
          hole,
          <ScorecardCheckbox
            checked={state.bingos[hole]}
            onChange={(event) => {
              setState((prevState) => {
                let bingos = prevState.bingos;
                bingos[hole] = !!event.target.checked;
                return { ...prevState, bingos: [...bingos] };
              });
            }}
          />,
        ])
      ),
    },
    {
      category: "Bango",
      ...Object.fromEntries(
        [...Array(18).keys()].map((hole) => [
          hole,
          <ScorecardCheckbox
            checked={state.bangos[hole]}
            onChange={(event) =>
              setState((prevState) => {
                let bangos = prevState.bangos;
                bangos[hole] = event.target.checked;
                return { ...prevState, bangos: [...bangos] };
              })
            }
          />,
        ])
      ),
    },
    {
      category: "Bongo",
      ...Object.fromEntries(
        [...Array(18).keys()].map((hole) => [
          hole,
          <ScorecardCheckbox
            checked={state.bongos[hole]}
            onChange={(event) =>
              setState((prevState) => {
                let bongos = prevState.bongos;
                bongos[hole] = event.target.checked;
                return { ...prevState, bongos: [...bongos] };
              })
            }
          />,
        ])
      ),
    },
  ];

  return (
    <Stack sx={{ display: "flex" }} justifyContent="space-between" spacing={2}>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <Table columns={columns} rows={rows} />
      </TableContainer>
      <Stack
        direction="row"
        sx={{ display: "flex" }}
        justifyContent="space-between"
        spacing={1}
      >
        <Button
          variant="contained"
          sx={{ flex: "1" }}
          startIcon={submitting ? null : <PublishIcon />}
          disabled={!ChangePending() || submitting}
          onClick={() => {
            setSubmitting(true);
            callback(state).finally(() => setSubmitting(false));
          }}
        >
          {submitting ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            "submit"
          )}
        </Button>
        <Button
          variant="contained"
          disabled={!ChangePending() || submitting}
          onClick={() => setState(JSON.parse(JSON.stringify(scorecard)))}
        >
          <RestorePageIcon sx={{ padding: "3px", width: "25px" }} />
        </Button>
      </Stack>
      <BingoBangoBongoRules game={game} />
    </Stack>
  );
}

export function BingoBangoBongoRules({ game }) {
  return "BingoBangoBongo";
}
