import React from "react";
import PublishIcon from "@mui/icons-material/Publish";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from "@mui/material";

import { ScorecardCheckbox, StrokeInput } from "../input_utils";
import { Table } from "../table";

export function WolfScorecard(props) {
  const { userId, scorecard, game, course, users, callback } = props;
  const [state, setState] = React.useState(
    JSON.parse(JSON.stringify(scorecard))
  );
  const [submitting, setSubmitting] = React.useState(false);

  const ChangePending = () => {
    for (let i = 0; i < 18; ++i) {
      if (state.wolf[i] !== scorecard.wolf[i]) return true;
      const state_p = Object.keys(state.partners[i]);
      const scorecard_p = Object.keys(scorecard.partners[i]);
      if (state_p.length !== scorecard_p.length) return true;
      for (const p of state_p) {
        if (!scorecard_p.includes(p)) return true;
      }
      if (state.strokes[i] !== scorecard.strokes[i]) return true;
    }
    return false;
  };

  const stickyStyle = {
    backgroundColor: "white",
    position: "sticky",
    left: "0",
    zIndex: "999",
  };

  const columns = [
    { id: "category", label: "Hole", align: "left", sx: stickyStyle },
  ].concat(
    [...Array(18).keys()].map((x) => ({
      id: x,
      label: `${x + 1}`,
      align: "center",
    }))
  );

  const rows = [
    {
      category: "Par",
      ...course.hole_par,
    },
    {
      category: "Index",
      ...course.hole_index,
    },
    {
      category: "Strokes",
      ...Object.fromEntries(
        [...Array(18).keys()].map((hole) => [
          hole,
          <StrokeInput
            value={state.strokes[hole] ? state.strokes[hole] : ""}
            onChange={(event) => {
              setState((prevState) => {
                let strokes = prevState.strokes;
                strokes[hole] = parseInt(event.target.value) || 0;
                return { ...prevState, strokes: [...strokes] };
              });
            }}
          />,
        ])
      ),
    },
    {
      category: "Wolf",
      ...Object.fromEntries(
        [...Array(18).keys()].map((hole) => [
          hole,
          <ScorecardCheckbox
            checked={state.wolf[hole]}
            onChange={(event) =>
              setState((prevState) => {
                let wolf = prevState.wolf;
                wolf[hole] = event.target.checked;
                return { ...prevState, wolf: [...wolf] };
              })
            }
          />,
        ])
      ),
    },
  ].concat(
    Object.values(game.config.groups)
      .find((group) => group.includes(userId))
      .filter((groupUserId) => groupUserId !== userId)
      .map((groupUserId) => ({
        category: users[groupUserId].name,
        ...Object.fromEntries(
          [...Array(18).keys()].map((hole) => [
            hole,
            <ScorecardCheckbox
              checked={Object.keys(state.partners[hole]).includes(groupUserId)}
              onChange={(event) =>
                setState((prevState) => {
                  let partners = prevState.partners;
                  delete partners[hole][groupUserId];
                  if (event.target.checked) partners[hole][groupUserId] = true;
                  return { ...prevState, partners: [...partners] };
                })
              }
            />,
          ])
        ),
      }))
  );

  return (
    <Stack sx={{ display: "flex" }} justifyContent="space-between" spacing={2}>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <Table columns={columns} rows={rows} />
      </TableContainer>
      <Stack
        direction="row"
        sx={{ display: "flex" }}
        justifyContent="space-between"
        spacing={1}
      >
        <Button
          variant="contained"
          sx={{ flex: "1" }}
          startIcon={submitting ? null : <PublishIcon />}
          disabled={!ChangePending() || submitting}
          onClick={() => {
            setSubmitting(true);
            callback(state).finally(() => setSubmitting(false));
          }}
        >
          {submitting ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            "submit"
          )}
        </Button>
        <Button
          variant="contained"
          disabled={!ChangePending() || submitting}
          onClick={() => {
            setState(JSON.parse(JSON.stringify(scorecard)));
          }}
        >
          <RestorePageIcon sx={{ padding: "3px", width: "25px" }} />
        </Button>
      </Stack>
      <WolfRules game={game} />
    </Stack>
  );
}

export function WolfRules({ game }) {
  const columns = [
    { id: "outcome", label: "Outcome", align: "center" },
    { id: "points", label: "Points", align: "center" },
  ];

  const threesome_rows = [
    { outcome: "Lone Wolf Wins", points: 6 },
    { outcome: "Team of 2 Wins", points: 3 },
    { outcome: "Tie", points: 2 },
  ];

  const foursome_rows = [
    { outcome: "Lone Wolf Wins", points: 4 },
    { outcome: "Wolf Team Wins", points: 2 },
    { outcome: "Nonwolf Team Wins", points: 3 },
    { outcome: "Tie", points: 0 },
  ];

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingTop: "25px" }}>
        Wolf Rules
      </Typography>
      <Typography>
        Players rotate being "wolf" on each hole in a fixed order throughout the
        match (e.g., in a foursome, player 1 would be wolf on holes 1, 5, 9,
        etc.).
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Threesome</strong>
      </Typography>
      <Typography>The wolf tees off first on each hole.</Typography>
      <Typography>
        After their tee shot, they can choose to be the lone wolf or to see the
        next tee shot.
      </Typography>
      <Typography sx={{ paddingLeft: "20px" }}>
        After player 2{"'"}s tee shot, the wolf must choose which other player
        to partner up with.
      </Typography>
      <Typography>
        The winner(s) of the hole (after strokes given) get points:
      </Typography>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <Table columns={columns} rows={threesome_rows} size="small" />
      </TableContainer>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Foursome</strong>
      </Typography>
      <Typography>The wolf tees off last on each hole.</Typography>
      <Typography>
        After each player{"'"}s tee shot (but before the wolf{"'"}s tee shot),
        the wolf can choose to pair up with them or not.
      </Typography>
      <Typography>
        The winner(s) of the hole (after strokes given) get points:
      </Typography>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <Table columns={columns} rows={foursome_rows} size="small" />
      </TableContainer>
    </>
  );
}
