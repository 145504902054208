import * as React from "react";
import { Checkbox, TextField } from "@mui/material";

export function ScorecardCheckbox(props) {
  return (
    <Checkbox
      size="small"
      sx={{ padding: "0" }}
      inputProps={{ "aria-label": "controlled" }}
      {...props}
    />
  );
}

export function StrokeInput(props) {
  return (
    <TextField
      variant="outlined"
      size="small"
      type="number"
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
        style: { textAlign: "center" },
      }}
      sx={{
        width: "50px",
      }}
      {...props}
    />
  );
}
