import { CircularProgress, Stack } from "@mui/material";

export function Loading() {
  return (
    <Stack
      sx={{ height: "75vh" }}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Stack>
  );
}
