import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import PublishIcon from "@mui/icons-material/Publish";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from "@mui/material";

import { StrokeInput } from "../input_utils";
import { Table } from "../table";

export function StablefordScorecard(props) {
  const { scorecard, game, course, callback } = props;
  const [state, setState] = React.useState(
    JSON.parse(JSON.stringify(scorecard))
  );
  const [submitting, setSubmitting] = React.useState(false);

  const ChangePending = () => {
    for (let i = 0; i < 18; ++i) {
      if (state.strokes[i] !== scorecard.strokes[i]) return true;
    }
    return false;
  };

  const stickyStyle = {
    backgroundColor: "white",
    position: "sticky",
    left: "0",
    zIndex: "999",
  };

  const columns = [
    { id: "category", label: "Hole", align: "left", sx: stickyStyle },
  ].concat(
    [...Array(18).keys()].map((x) => ({
      id: x,
      label: `${x + 1}`,
      align: "center",
    }))
  );

  const rows = [
    {
      category: "Par",
      ...course.hole_par,
    },
    {
      category: "Index",
      ...course.hole_index,
    },
    {
      category: "Strokes",
      ...Object.fromEntries(
        [...Array(18).keys()].map((hole) => [
          hole,
          <StrokeInput
            value={state.strokes[hole] ? state.strokes[hole] : ""}
            onChange={(event) => {
              setState((prevState) => {
                let strokes = prevState.strokes;
                strokes[hole] = parseInt(event.target.value) || 0;
                return { ...prevState, strokes: [...strokes] };
              });
            }}
          />,
        ])
      ),
    },
  ];

  return (
    <Stack sx={{ display: "flex" }} justifyContent="space-between" spacing={2}>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <Table columns={columns} rows={rows} />
      </TableContainer>
      <Stack
        direction="row"
        sx={{ display: "flex" }}
        justifyContent="space-between"
        spacing={1}
      >
        <Button
          variant="contained"
          sx={{ flex: "1" }}
          startIcon={submitting ? null : <PublishIcon />}
          disabled={!ChangePending() || submitting}
          onClick={() => {
            setSubmitting(true);
            callback(state).finally(() => setSubmitting(false));
          }}
        >
          {submitting ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            "submit"
          )}
        </Button>
        <Button
          variant="contained"
          disabled={!ChangePending() || submitting}
          onClick={() => setState(JSON.parse(JSON.stringify(scorecard)))}
        >
          <RestorePageIcon />
        </Button>
      </Stack>
      <StablefordRules game={game} />
    </Stack>
  );
}

export function StablefordRules({ game }) {
  const columns = [
    { id: "net_to_par", label: "Net to Par", align: "center" },
    { id: "points", label: "Points", align: "center" },
  ];

  const rows = Object.entries(game.config.point_multipliers)
    .sort((a, b) => {
      const a_int = parseInt(a);
      const b_int = parseInt(b);
      if (a_int < b_int) return 1;
      if (a_int > b_int) return -1;
      return 0;
    })
    .map(([net, points]) => ({ net_to_par: net, points: points }));

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ paddingTop: "25px" }}>
        Stableford Rules
      </Typography>
      <Typography>
        Like normal stroke play, but instead of just adding up all of your
        strokes, you get points based on your net score to par (after strokes
        given) on each hole.
      </Typography>
      <Typography>Per-hole point values are:</Typography>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <Table columns={columns} rows={rows} size="small" />
      </TableContainer>
    </>
  );
}
